html {
  font-size: 62.5%;
}

body {
  user-select: none;
}

header.iq-layout-header {
  padding: 0.8rem;
}

header.iq-layout-header .header-button {
  margin-right: 0.8rem;
}

header.iq-layout-header .header-button .header-settings-button {
  margin-left: 0.8rem;
}

header.iq-layout-header .iq-header-extra {
  flex: none;
  flex-direction: row;
}

header.iq-layout-header .iq-header-title p {
  color: var(--iq-color);
  font-weight: 400;
}

footer.iq-layout-footer {
  padding: 0.8rem;
  text-align: center;
  font-weight: 500;
  font-size: 80%;
  text-transform: uppercase;
}

.segments-wrapper {
  width: 100%;
}

.segment {
  margin: 0 0 1.6rem;
  overflow: hidden;
}

.segment .header {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 0.8rem;
}

.segment .header .title {
  font-size: 1.6rem;
  font-weight: 700;
  width: 4rem;
  line-height: 3rem;
}

.segment .header .preview {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.segment .header .title small {
  line-height: 1;
  font-size: 70%;
  opacity: 0.5;
}

.segment .header .iq-input {
  width: 4rem;
  margin: 0;
  background: transparent;
}
.segment .header .iq-input input {
  text-align: center;
  color: var(--iq-divider);
  padding: 0.4rem 0.4rem 0.2rem 0.4rem;
  line-height: 2.2rem;
}
.segment .header .iq-input .iq-button {
  display: none;
}
.segment .header .iq-input .bar {
  /*display: none;*/
}

.segment .konva-wrap {
  box-sizing: content-box;
  background: transparent !important;
  display: flex;
  justify-content: center;
  min-width: 16rem;
}

.segment .konva {
  box-sizing: border-box;
}

.iq-light, .iq-dark, :root {
  --iq-scrollbar-width: 1.6rem;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
}

@media (min-width: 32rem) {
  .iq-light, .iq-dark, :root {
    --iq-scrollbar-width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }

  .segments-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    padding: 1.6rem;
  }

  .segment {
    margin: 1.6rem;
    box-shadow: var(--iq-elevation-3-shadows);
    background: var(--iq-scrollbar);
    overflow: hidden;
    border-radius: 0.4rem;
  }
}
